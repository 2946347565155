import tw from 'twin.macro';

import RatingStar from '@components/elements/Form/RatingStar';

const RatingWithStars = ({
  value = 0,
  isSmall = false,
  starSize,
  fractions = 2,
  isReadOnly = false,
}) => {
  return (
    <div tw="flex items-center">
      <div css={[tw`mr-3`, isSmall ? tw`text-lg` : tw`text-2xl`]}>
        <span tw="font-semibold">{Math.round(value * 10) / 10}</span>
        <span css={{ fontSize: '0.675em' }}>/5</span>
      </div>
      <div>
        <RatingStar
          value={value}
          quiet={isReadOnly}
          starSize={starSize}
          readonly={isReadOnly}
          fractions={fractions}
        />
      </div>
    </div>
  );
};

export default RatingWithStars;
