import RouterLink from 'next/link';

import { css } from '@emotion/react';
import tw from 'twin.macro';

import Button from '@components/elements/Button';
import DishImage from '@components/modules/DishTile/DishImage';
import Rating from '@components/modules/Reviews/Rating';
import { cssMerge } from '@utils/styleHelpers';

import OverflowContent from '../OverflowContent';

const ProductCard = props => {
  const {
    image,
    hideImage = false,
    anchorLink,
    anchorText,
    buttonText,
    productName,
    productLongName,
    productPrice,
    pricePrefix,
    rating,
    onCardClick,
    dishImageBackground,
    dishImageBackgroundEnabled,
    dishImagePlaceholder,
    resources,
    homePage = false,
  } = props;

  let croppedName = productName;

  if (productName.length > 50) {
    croppedName = croppedName.substring(0, 47) + '...';
  } else {
    croppedName = productName;
  }

  const onButtonClick = e => {
    e.stopPropagation();
    props.onButtonClick();
  };

  return (
    <div
      onClick={onCardClick}
      css={[
        tw`flex flex-col w-full h-full rounded-lg relative border-2 overflow-hidden`,
        !!onCardClick &&
          tw`transition-colors duration-300 cursor-pointer hover:(border-primary duration-300)`,
        css`
          min-height: 325px;
        `,
      ]}
    >
      {rating && (
        <div tw="absolute right-2 top-1 z-10">
          <Rating value={rating} isReadOnly starSize="1x" isSmall />
        </div>
      )}
      <DishImage
        backgroundEnabled={dishImageBackgroundEnabled}
        backgroundImageUrl={resources?.[dishImageBackground]}
        imageUrl={image}
        placeholderSrc={resources?.[dishImagePlaceholder]}
        showImage={!hideImage}
      />
      <div
        css={css`
          ${tw`flex flex-col flex-1 p-2 -mt-2 overflow-hidden bg-white z-10`}
          border-radius: 8px;
        `}
      >
        <div tw="flex justify-between flex-1">
          {productLongName ? (
            <h4 tw="text-sm font-semibold text-inherit">{productLongName}</h4>
          ) : (
            <OverflowContent
              as="h3"
              lineClamp={3}
              styles={{
                css: cssMerge({
                  defaultCss: tw`px-2 text-base text-inherit h-[50px] flex items-center justify-center`,
                  css: !productPrice && tw`flex-1 text-center`,
                }),
              }}
              tooltipProps={{
                tooltipContent: productName,
                showTooltip: productName.length > 50,
              }}
            >
              {croppedName}
            </OverflowContent>
          )}
          {productPrice && (
            <div tw="whitespace-nowrap">
              <span tw="text-xs">{pricePrefix}</span>
              <span tw="text-sm font-semibold"> {productPrice}</span>
            </div>
          )}
        </div>
        {anchorText && (
          <div tw="flex justify-center text-sm">
            <RouterLink href={anchorLink} passHref legacyBehavior>
              <a tw="mx-auto pb-2 no-underline">{anchorText}</a>
            </RouterLink>
          </div>
        )}
        {buttonText && (
          <Button
            css={[homePage && tw`px-0`]}
            fullWidth
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
