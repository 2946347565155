import Rating from 'react-rating';
import { faStar as farFaStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as fasFaStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tw from 'twin.macro';

const RatingStar = ({
  onChange,
  value = 0,
  disabled = false,
  starSize = 'lg',
  ...restProps
}) => {
  const twStyle = disabled
    ? [{ height: '50px' }, tw`cursor-not-allowed opacity-70`]
    : tw`text-yellow-0`;

  return (
    <Rating
      emptySymbol={
        <FontAwesomeIcon icon={farFaStar} css={twStyle} size={starSize} />
      }
      fullSymbol={
        <FontAwesomeIcon icon={fasFaStar} css={twStyle} size={starSize} />
      }
      onChange={onChange}
      initialRating={value}
      readonly={disabled}
      fractions={10}
      {...restProps}
    />
  );
};

export default RatingStar;
